//$primary:       $blue !default;
//$secondary:     $gray-600 !default;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
$dark: #2f2f2f;

$font-family-sans-serif:      'Montserrat', sans-serif;
//$font-family-base:            'Crimson Text', serif;
$font-family-base:            'Montserrat', sans-serif;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

/***********************************************************************************************************************/

html,
body{
    height: 100%;
}

#root{
    height: 100%;
}
body{
    font-family: $font-family-base;
    //font-size: 20px;
    font-size: 16px;
}


/// BURGER MENU START
.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 20px;
    color: #fff;
    transition: color 0.2s;
    outline: none;
}

.bm-item:hover {
    color: white;
}


.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    margin: 0 15px;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}
.bm-menu-wrap{
    top: 53px;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #fff;
    height: 10%;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    display: none;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
    background: #2f2f2f;
    padding: 30px;
    @extend .caps;
    @extend .bold;
    font-size: 12px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 53px;
}
/// BURGER MENU END


.loader{
    display: flex;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
}

.caps {
    text-transform: uppercase;
    letter-spacing: .05rem;
    font-family: $font-family-sans-serif;
}

.bold {
    font-weight: 600;
}

.thin{
    font-weight: 200;
}

.title{
    @extend .bold;
    @extend .caps;
    font-size: 28px;
}

.subtitle{
    @extend .bold;
    @extend .caps;
    font-size: 22px;
    margin-bottom: 25px;
}

.menu {
    display: flex;
    background-color: $dark;
    justify-content: space-between;
    height: 53px;

    .link-tgp{
        @include media-breakpoint-down(md) {
            display: none !important;
        }
    }

    .logo-tgp {
        width: 250px;
        margin: 10px 0;

    }

    .left,
    .right {
        display: flex;
        align-items: center;
        color: #fff;
        > a {
            @extend .caps;
            color: #fff;
            text-decoration: none;
            font-size: 12px;
            padding: 0 15px;
            display: flex;
            height: 100%;
            align-items: center;

            &:hover {
                background-color: darken($dark, 5%);
            }
        }
    }
    .bullet{
        font-size: 4px;
    }



    .pages {
        @extend .list-unstyled;
        margin: 0;
        flex: 1;
        align-items: center;
        height: 100%;
        display: flex;

        li {
            display: flex;
            align-items: center;
            height: 100%;

            a {
                height: 100%;
                align-items: center;
            }
        }
    }

    .by {
        display: flex;
        align-items: center;
    }
}


.authors{
    margin: 20px 0 30px;
    display: flex;
    flex-wrap: wrap;
}
.bloc-author{
    display: flex;
    align-items: center;
    width: 280px;
    margin: 0 30px 20px 0;
    img{
        width: 75px;
        border-radius: 50%;
        margin-right: 15px;
    }
    .author{
        .name{
            font-size: 18px;
            @extend .caps;
            @extend .bold;
            letter-spacing: 1px;
            line-height: 1.2em;
            margin-bottom: 5px;
        }
        .job-title{
            font-size: 16px;
            line-height: 1rem;
        }
    }
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent url('assets/bg-header.jpg') center no-repeat;
    background-size: cover;
    min-height: 317px;
    justify-content: center;
    .page-title{
        color: #fff;
        text-align: center;
        h1{
            font-size: 38px;
        }
        h2{
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
        }
        .subtitle{
            margin-top: 10px;
        }
        .by{
            text-transform: none;
            font-size: 14px;
            margin-top: 10px;
        }
    }
    .logos-partners{
        display: flex;
        margin-left: 20px;
        align-items: center;
        .and{
            margin: 0 20px;
        }
    }
    &.big-header {
        .page-title{
            margin: 50px 0;
        }
    }
    .tabs-menu{
        ul{
            @extend .list-unstyled;
            display: flex;
            align-items: center;
            justify-content: center;
            @extend .caps;
            @extend .bold;
            font-size: 13px;
            li{
                a{
                    color: #fff;
                    margin: 0 15px;
                }
            }
        }
    }
}


.editorial-content{
    padding: 50px 0;
    article{
        .article-title{
            @extend .title;
            margin-bottom: 30px;
        }
    }
    a{
        text-decoration: underline;
        color: $dark;
    }
    h3{
        @extend .bold;
        @extend .caps;
        font-size: 18px;
        margin-top: 30px;
    }
}



.sep{
    margin: 50px auto;
    border-top: 1px solid $dark;
    width: 300px;
}
.minisep{
    margin: 50px 0;
    border-top: 1px solid $dark;
    width: 150px;
}


//menu en position fixed
.inpage-nav{
    position: absolute;
    //font-size: 16px;
    //width: 200px;
    //left: 30px;
    //top: 440px;

    font-size: 14px;
    width: 280px;
    left: 0;
    top: 412px;
    background-color: #efefef;
    padding: 30px;

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    &.sticky {
        position: fixed;
        top: 0px;
    }
    ul{
        @extend .list-unstyled;
        li{
            &.active {
                >a {
                    position: relative;
                    &:before{
                        content: '-';
                        position: absolute;
                        left: -17px;
                    }
                }
            }
        }
    }
    >ul{
        >li{
            margin-bottom: 15px;
            >a{
                @extend .caps;
                @extend .bold;
                font-size: 12px;
            }
            ul{
                padding-left: 15px;
                //display: none;
            }
            &.active{
                ul{
                    display: block;
                }
            }
        }

    }
    a{
        display: flex;
        text-decoration: none;
        margin-bottom: 10px;
        line-height: 1rem;
        color: $dark;
    }
}

#page-wrap{
    overflow: hidden;
}
.solutions{
    position: relative;
    section{
        position: relative;
    }
    .section-title{
        @extend .bold;
        @extend .caps;
        font-size: 24px;
        margin: 50px 0 20px;
    }
    .section-number{
        margin: 50px -30px 30px;
        //border: 1px solid $dark;
        background-color: $dark;
        color: #fff;
        padding: 30px;
        .inner{
            display: flex;
            align-items: center;
        }

        .number {
            @extend .caps;
            font-size: 50px;
            margin-right: 30px;

        }
        //@include media-breakpoint-up(xl) {
        //    position: absolute;
        //    right: -360px;
        //    width: 330px;
        //    top: -70px;
        //}
    }
    .solution{
        padding: 30px;
        margin-left: -30px;
        margin-right: -30px;
        .solution-title{
            //font-size: 24px;
            font-size: 18px;
        }
        .more{
            margin-left: 15px;
            font-size: 12px;
            display: inline;
            @extend .caps;
            @extend .bold;
            letter-spacing: .5px;
            span{
                &:not(:first-of-type){
                    &:before{
                        content: '•';
                        margin-right: 8px;
                    }
                    margin-left: 8px;
                }
            }
        }
        &.enabled{
            &:hover{
                background-color: #efefef;
                cursor: pointer;
            }
        }


        .solution-info{
            display: flex;
            align-items: center;
            margin-top: 20px;
            .cursors{
                margin-top: 0;
            }
            .more{
                &:before{
                    content: '|';
                    margin-right: 20px;
                }
            }
        }
    }
}

.cursors{
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 15px;
    .cursor{
        margin-right: 20px;
        .icon{
            color: #ccc;
            &.active{
                color: $dark;
            }
        }
        &.cursor-cost{
            .icon{
                color: transparentize(#eca806, .6);
                &.active{
                    color: #eca806;
                }
            }
        }
        &.cursor-impact{
            .icon{
                color: transparentize(#6e9506, .6);
                &.active{
                    color: #6e9506;
                }
            }
        }
        &.cursor-time{
            .icon{
                color: transparentize(#3183bd, .6);
                &.active{
                    color: #3183bd;
                }
            }
        }

    }
}

.providers{
    @extend .list-unstyled;
    a{
        color: $dark;
        &:hover{
            text-decoration: underline;
        }
    }
}

.slide-pane__overlay{
    z-index: 50;
}

.slide-pane{
    .slide-pane__header{
        background-color: transparent;
        border-bottom: 0 none;
    }
    .slide-pane__content{
        padding: 50px;
        >div{
            max-width: 750px;
        }
        .title{
            font-size: 24px;
        }
    }
    section{
        margin-top: 50px;
    }
    .quote{
        font-style: italic;
        font-size: 26px;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    .cursors{
        font-size: 18px;
    }

    h3{
        @extend .caps;
        @extend .bold;
        font-size: 18px;
        margin-top: 40px;
        margin-bottom: 5px;
    }

    .company{
        @extend .caps;
        small{
            display: block;
            @extend .bold;
            font-size: 14px;
        }
        margin-top: 0;
    }
    .bloc-author{
        width: auto;
        img{
            width: 128px;
            margin-right: 25px;
        }
        .job-title{
            @extend .subtitle;
            font-size: 12px;
            margin-bottom: 0;
        }
    }
}

.bloc-company{
    background-color: #efefef;
    padding: 50px;
}

.numbers{
    display: flex;
    margin-top: 30px;
    .number{
        width: 150px;
        &:not(:last-of-type){
            margin-right: 30px;
            border-right: 1px solid $dark;
            padding-right: 30px;
        }
        .libelle{
            @extend .caps;
            @extend .bold;
            small{
                @extend .bold;
                font-size: 60%;
            }
        }
        .desc{
            font-size: 16px;
            line-height: 1.2em;
        }
    }
}

.notes{

    ul{
        font-size: 16px;
        list-style-type: none;
        padding-left: 15px;
        li{
            margin-bottom: 5px;
        }
    }
}

.toplink{
    padding: 20px;
    a{
        color: lighten($dark, 20%);
        &:hover{
            color: $dark;
        }
    }
}


.g-btn{
    padding: 10px 15px;
    @extend .caps;
    text-decoration: none;
    border: 1px solid $dark;
}


.solution-filters{
    margin-top: 50px;
    display: flex;
    align-items: center;
    margin-left: -30px;
    margin-right: -30px;
    .filter{
        flex: 1;
        padding: 0 10px;
        &:not(:last-of-type){
            border-right: 1px solid #efefef;
        }
        label{
            font-size: 13px;
            line-height: 1em;
        }

    }
}


//select filters

//.css-yk16xz-control,
//.css-yk16xz-control:hover,
//.css-1pahdxg-control,
//.css-1pahdxg-control:hover{
//    border-color: #efefef !important;
//    box-shadow: none;
//    outline: none;
//}


.react-select-container{
    font-size: 12px;
    .far{
        margin-right: 3px;

    }
    .react-select__option--is-focused,
    .react-select__option--is-selected{
        background-color: #efefef;
        color: $dark;
    }
}
.react-select__control{
    border: 0 none !important;
    box-shadow: none !important;

}
.filter-speed{
    .far{
        color: transparentize(#3183bd, .6) !important;
        &.active{
            color: #3183bd !important;
        }
    }
}
.filter-impact{
    .far{
        color: transparentize(#6e9506, .6) !important;
        &.active{
            color: #6e9506 !important;
        }
    }
}
.filter-cost{
    .far{
        color: transparentize(#eca806, .7) !important;
        &.active{
            color: #eca806 !important;
        }
    }
}
